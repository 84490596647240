@font-face {
  font-family: MADEGentle;
  src: url("assets/fonts/MADE Gentle PERSONAL USE.otf");
}

.App {
  text-align: center;
  font-family: MADEGentle, sans-serif;
}

.nav {
  position: fixed;
}

#collection-component-1605391327262 {
  max-width: 90%;
  margin: 0 auto;
}

.nav.btn,
.nav.btn:active,
.nav.btn:hover,
.nav.btn:visited,
.nav.btn:focus-within,
.nav.btn:focus{
  padding: 15px !important;
  text-align: center !important;
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  text-transform: uppercase;
}

.logo {
  left: 50%;
  width: 80px;
  margin-left: -40px; /* Half of the width */
}

.content {
  margin: 15vh 0 !important;
  max-height: 100vh;
}
.row {
  /*margin: 25px;*/
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.album {
  height: 55vh;
  margin: auto;
  animation-name: fadeIn;
  animation-duration: 2s;
}

.album-image {
  width: 55vh;
}

@media screen and (max-width: 450px){
  .album {
    height: 35vh;
  }
  .album-image {
    width: 35vh;
  }
}

.nav {
  z-index: 999;
}

.nav.top-center {
  top: 0;
  left: 50%;
  right: 50%;
}

button.top-left {
  top: 0;
  left: 0;
}
button.top-right {
  top: 0;
  right: 0;
}
button.bottom-right {
  bottom: 0;
  right: 0;
}
button.bottom-left {
  bottom: 0;
  left: 0;
}
button.bottom-center {
  bottom: 0;
  left: 50%;
  right: 50%;
  width: 134px;
  margin-left: -67px; /* Half of the width */
}

.roster.headshot {
  margin: 0 auto;
  width: 600px;
  height: 600px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  /*border-radius: 50%;*/
}

@media only screen and (max-width: 600px) {
  .roster.headshot  {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .roster.headshot  {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 800px) {
  .roster.headshot  {
    width: 325px;
    height: 325px;
  }
}

@media only screen and (max-width: 1024px) {
  .roster.headshot  {
    width: 400px;
    height: 400px;
  }
}



.roster {
  text-align: left;
}
.roster .bio {
  margin-top: 25px;
}

.roster.info {
  padding: 40px;
}

.social-icons svg {
  margin-right: 20px;
  color: gray;
}
.social-icons svg:hover {
  margin-right: 20px;
  color: black;
  transition: .5s;
}

.roster.headshot-container {
  margin: 0 auto;
  width:auto;
  text-align:center;
  padding:20px;
}

.hidden {
  visibility: hidden;
}